import keys from "./data/keys.json";
import items from "./data/items.json";

export interface Score {
    score: string;
    min: number;
    max: number;
}

export interface Key {
    category: string;
    scores: Score[];
}

interface Result {
    kategori: string;
    puan: number;
}

function calculatePuan(i: number, sonuclar: Result[]) {
    const item = items.find(p => p.id == i);
    if (item) {
        const sonuc = sonuclar.find(p => p.kategori == item.category);
        if (sonuc)
            sonuc.puan++;
    }
}

export const calculateScore = (category: string, score: number) => {
    if (score > 20) { console.error("score 20'den buyuk olamaz"); return; }
    const anahtarlar = keys.find(p => p.category == category);
    if (anahtarlar) {
        const puan = anahtarlar.scores.find(p => p.max >= score && p.min <= score)
        return { toplam: score, score: puan?.score, puan };
    }

}

export const categoriPuaniHesapla = (cevaplar: string[]) => {
    const sonuclar: Result[] = [
        { kategori: "activist", puan: 0 },
        { kategori: "reflector", puan: 0 },
        { kategori: "theorist", puan: 0 },
        { kategori: "pragmatist", puan: 0 },
    ];

    for (let i = 0; i < cevaplar.length; i++) {
        const element = cevaplar[i];
        if (element === "1") {
            calculatePuan(i, sonuclar);
        }

    }
    return sonuclar;

}

export function herSeyiHesapla(cevaplar: string[]) {

    const puanlar = categoriPuaniHesapla(cevaplar);

    const activist = calculateScore("activist", puanlar[0].puan);
    const reflector = calculateScore("reflector", puanlar[1].puan);
    const theorist = calculateScore("theorist", puanlar[2].puan);
    const pragmatist = calculateScore("pragmatist", puanlar[3].puan);

    return { activist, reflector, theorist, pragmatist }

}


export function createGrafik(obj: any) {
    console.log(obj)
    const graf = {
        type: 'pie',
        data: {
            labels: ['Activist', 'Reflector', 'Theorist', 'Pragmatist'],
            datasets: [{
                data: [obj.activist.toplam, obj.reflector.toplam, obj.theorist.toplam, obj.pragmatist.toplam],
                backgroundColor: [
                    'rgba(215, 40, 40, 0.9)',
                    'rgba(218, 166, 44, 0.9)',
                    'rgba(0, 255, 255, 0.9)',
                    'rgba(71, 87, 255, 0.9)'
                ],
            }],
        },
        options: {
            responsive: true,

        }
    }
    return graf;
}