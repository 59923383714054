





































































import { Component, Prop, Vue } from "vue-property-decorator";
import { herSeyiHesapla, createGrafik } from "../services";
import Chart from "chart.js";

// Items :https://jsonblob.com/1730e65a-3d5f-11eb-8978-1505d169b147
import json from "../data/items.json";
@Component({
  components: {},
})
export default class HelloWorld extends Vue {
  @Prop() private msg!: string;
  items = json;
  i = 1;
  radio = [];
  currentItem = this.items.find((p) => p.id === 1);

  showGrafik = false;
  mounted() {
    const cevaplar: any = [];
    for (let i = 1; i < 81; i++) {
      cevaplar[i] = cevaplar[i - 1] == "1" ? "0" : "1";
      //cevaplar[i] = "0";
    }
    // Activist
    cevaplar[2] = "1";
    cevaplar[4] = "1";
    cevaplar[6] = "1";
    cevaplar[10] = "1";
    cevaplar[17] = "1";
    cevaplar[23] = "1";
    cevaplar[24] = "1";
    cevaplar[32] = "1";
    cevaplar[34] = "1";
    cevaplar[38] = "1";
    cevaplar[40] = "1";
    cevaplar[43] = "1";

    // Reflector
    cevaplar[7] = "1";
    cevaplar[13] = "1";
    cevaplar[15] = "1";
    cevaplar[16] = "1";
    cevaplar[25] = "1";

    const puanlar = herSeyiHesapla(cevaplar);
    console.log(puanlar);
    this.createChart("planet-chart", createGrafik(puanlar));
  }

  createChart(chartId: string, chartData: any) {
    const ctx = this.$refs.chart as HTMLCanvasElement;

    const myChart = new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    });
  }

  next() {
    console.log(this.radio[this.i]);
    if (!this.radio[this.i]) {
      this.$buefy.toast.open("Lütfen bir seçenek işaretleyin");
      return;
    }
    if (this.items[this.items.length - 1].id == this.i) {
      this.end();
      return;
    }
    this.i++;
    this.currentItem = this.items.find((p) => p.id === this.i);
    console.log(this.radio);
  }

  previos() {
    if (!this.radio[this.i]) return;
    this.i--;
    this.currentItem = this.items.find((p) => p.id === this.i);
  }

  end() {
    alert("kurs bitti");
    const sonuc = herSeyiHesapla(this.radio);
    console.log(sonuc);
  }
}
