






import { Component, Vue } from "vue-property-decorator";
import Questionnaire from "./components/Questionnaire.vue";
import Home from "./components/Home.vue";
import { runAllTests } from "./testler";

@Component({
  components: {
    Questionnaire,
    Home,
  },
})
export default class App extends Vue {
  view = "questionnaire";

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("page");
    console.log(`page: ${page}`);
    this.view = page != null ? page : "questionnaire";

    // Testler, production'da bu testleri mutlaka kaldır
    //runAllTests();
  }
}
